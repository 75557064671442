<template>
  <el-dialog
    :title="'选择资源'"
    :visible.sync="openDialog"
    width="70%"
    :show-close="false"
    class="dialog-vertical"
    @close="cancel"
  >
    <div class="member-list">
      <el-table
        :data="list"
        style="width: 100%"
        :height="350"
        ref="multipleTable"
        :highlight-current-row="!selectType"
        @selection-change="handleSelectionChange"
        v-loading="loading"
      >
        <!-- 多选 -->
        <el-table-column
          ref="selection"
          fixed
          type="selection"
          width="70"
          :selectable="selectable"
          :key="Math.random()"
        ></el-table-column>

        <template v-for="(th, index) in thead">
          <el-table-column
            :prop="th.prop"
            :label="th.label"
            :width="th.width || ''"
            :key="index"
          >
            <template slot-scope="{ row }">
              <p style="white-space: pre-wrap">
                {{ row[th.prop] | placeholder }}
              </p>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <Pagination :total="total" :page="filterForm.page" :change="onChangPage" />
    <template #footer>
      <el-button size="small" @click="openDialog = false">取消</el-button>
      <el-button size="small" type="primary" @click="submit">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import Pagination from '@/base/components/Default/Pagination'
import debounce from "lodash/debounce";
import {
    getContent
} from '../../api/CycIntroducePage'
import {getCampusAndPeriod, getEntranceYears} from '../../../organization-admin/api/member/member-list'

export default {
  name: 'contents-selector',
  components: { Pagination },
  model: {
    prop: 'open',
    event: 'closeDialog',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    // 已选择数据
    Contents: {
      type: Array,
      default: () => [],
    },
    // 0:单选/1:多选模式
    selectType: {
      type: Number,
      default: 1,
    },
    // 选择数量限制
    limit: {
      type: Number,
      default: 99,
    },
    
  },
  mounted() {},
  data() {
    const thead = [
    { label: '内容', prop: 'content_text', min_width: 100 },
        { label: '内容类型', prop: 'content_type', min_width: 100 },
        { label: '内容分类', prop: 'category_name', min_width: 100 },
        { label: '发布人人', prop: 'nickname', min_width: 100 },
        {label: '发布时间', prop: 'create_time', min_width: 120}
    ]
    return {
      EntranceYears: [],
      EntranceYearsData: [],
      CampusAndPeriod: [],
      CampusData: [],
      PeriodData: [],
      positionIds: [],
      loading: false, //加载
      thead,
      list: [],
      //筛选对象
      filterForm: {
        page_size: 10, //每页多少条数据
        page: 1,
      },
      getable: true, //是否可以请求列表
      // 联级选择配
      currentRow: '', // 单选时选中的数据项
      selectArr: [], //勾选的数组id
      // 数据总量
      total: 100,
    }
  },
  methods: {
    //分页事件
    onChangPage(pageObj) {
      this.getable = true
      console.log(pageObj)
      Object.assign(this.filterForm, pageObj)
      this.getSearchlist(this.filterForm)
    },
    // 获取会员列表
    getSearchlist: debounce(function (pageData) {
      if (this.getable) {
        this.loading = true
        getContent({selected_ids:this.Contents.map(item=>item.id),...this.filterForm})
          .then((res) => {
            const { data } = res
     
            this.total = data.total
            this.list = data.data
            console.log(data);
            if (data.data.length < this.filterForm.page_size) {
              this.getable = false
            }
            // this.filterForm.page++
            this.loading = false
          })
          .catch((err) => {})
      }
    }, 200),
    getSchoolFilterList() {
      // TODO: 刷新
      getEntranceYears()
        .then((res) => {
          this.EntranceYears = []
          const { data } = res
          for (let i = 0; i < data.length; i++) {
            this.EntranceYears.push({
              label: data[i],
              value: data[i],
            })
          }
        })
        .catch((err) => {})
      getCampusAndPeriod()
        .then((res) => {
          const { data } = res
          this.CampusAndPeriod = data
        })
        .catch((err) => {})
    },
    // 筛选操作
    handleFilter(e, key) {
      this.filterForm.page = key ? filterForm.page + 1 : 1
      const fliterData = { ...this.filterForm }
      fliterData.organization_id =
        fliterData.organization_id === '' ? -1 : fliterData.organization_id
      fliterData.position_id =
        fliterData.position_id === '' ? -1 : fliterData.position_id
      this.getable = true
      this.getSearchlist(fliterData)
    },
    // 勾选操作
    handleSelectionChange(selection) {
      console.log(selection,this.selectArr);
      if (!selection.length) {
        this.selectArr = []
      } else {
        if (selection.length > this.limit) {
          this.selectArr = selection.slice(0, this.limit)
          const leftArr = selection.slice(this.limit)
          this.$message.error(`最多可选择${this.limit}个资源`)
          leftArr.forEach((el) => {
            this.$refs.multipleTable.toggleRowSelection(el, false)
          })
        } else {
          this.selectArr = [...selection]
        }
      }
    },
    selectable(row, index) {
      if (
        this.selectedContents.findIndex(
          (item) => item.id === row.id
        ) !== -1
      ) {
        return false;
      } else {
        return true;
      }
    },
    //触底刷新
    LoadMore() {
      if (this.getable) {
        this.handleFilter(this.filterForm, 'Load')
      }
    },
    // 确定
    submit() {
      if(this.selectArr.length === 0){
        this.$message.info('请选择数据项')
      }
      this.$emit('changeSelect', this.selectArr)
      this.openDialog = false
    },
    // 取消
    cancel(formName) {
      this.openDialog = false
    },
  },
  watch: {
    openDialog(val) {
      if (val) {
        const data = { ...this.filterForm, page: 1 }
        this.getSearchlist(data)
      } else {
        this.selectArr = []
      }
    },
    list: {
      deep: true,
      handler(val) {
        this.list.forEach((el) => {
          if (
            this.selectArr.findIndex((elem) => elem.id === el.id) !==
              -1 &&
            this.$refs.multipleTable
          ) {
            this.$nextTick(() => {
              this.$refs.multipleTable.toggleRowSelection(el, true)
            })
          }
        })
      },
    },
  },
  computed: {
    tenantVersion() {
      return this.$store.getters.tenant.version
    },

    openDialog: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('closeDialog', val)
      },
    },
    selectedContents: {
      get() {
        return this.Contents
      },
      set(val) {
        this.$emit('changeSelect', val)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.check-post {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.head-filter {
  // width: 80%;
  display: flex;
  position: absolute;
  top: 40px;
  right: 40px;
  justify-content: flex-end;

  .el-select,
  .el-cascader,
  .el-input {
    flex: 1;
    margin-right: 10px;
  }
}

.member-list {
  ::v-deep.el-table__row {
    cursor: pointer;
  }
  ::v-deep.el-table__fixed-header-wrapper  .el-checkbox{
	display:none
  }
}

.img-tips {
  font-size: 12px;
  color: #c0c4cc;
  line-height: 24px;
}
</style>
